import { getRandomInt } from "../utilities";

class CharacterJumps {
  constructor ( elArray ) {

    let length = elArray.length;
    let characters = [];
    let shadows = [];
    let index = 0;

    elArray.forEach(el => {
      characters.push(el.querySelector('.char'))
      shadows.push(el.querySelector('.shadow'))
    });

    function jump () {
      requestAnimationFrame(function () {
        let el = elArray[index];
        characters[index].style.transitionTimingFunction= 'ease-out';
        shadows[index].style.transform = 'ease-out';
        characters[index].style.transform = 'translateY(-10%)';
        shadows[index].style.transform = 'scale(1.125)';
        setTimeout(function () {
          requestAnimationFrame(function () {
            characters[index].style.transitionTimingFunction= 'ease-in';
            shadows[index].style.transform = 'ease-in';
            characters[index].style.transform = 'translateY(0)';
            shadows[index].style.transform = 'scale(1)';
            index = index < length - 1 ? index + 1 : 0;
            let timeOut = getRandomInt(800, 1200);
            setTimeout(jump, timeOut);
          });
        }, 350);
      });
    }

    setTimeout(function() {
      jump();
    }, 1000);
    // setInterval(jump, 1000);

    console.log('characters jumps', elArray);
  }
}

export default CharacterJumps;
