/**
 * RAF Standalone Animation Loop
 * */


function AnimationLoop(onLoop, context, interval) {

  let animLoop = this;
  let then;
  let delta;
  let accuracy;
  interval = interval === undefined ? 1000 / 60 : interval;

  animLoop.paused = false;
  animLoop.stopped = false;

  function loop(now) {

    if(animLoop.stopped) {
      return;
    }

    if (!then) then = now;
    delta = now - then;
    if (delta >= interval) {
      accuracy = delta / interval;

      if(!animLoop.paused) {
        onLoop.call(context, accuracy);
      }

      then = now;
    }
    animLoop.raf = window.requestAnimationFrame(loop);
  }

  animLoop.raf = window.requestAnimationFrame(loop);

  return animLoop;

}

AnimationLoop.prototype.stop = function () {
  this.stopped = true;
  window.cancelAnimationFrame(this.raf);
};

AnimationLoop.prototype.pause = function () {
  this.paused = true;
};

AnimationLoop.prototype.resume = function () {
  this.paused = false;
};

export default AnimationLoop;
