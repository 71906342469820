import { qsArray } from "../utilities";

class PokemonCarousel {
  constructor(elementId) {
    let parent = document.getElementById(elementId);
    let carouselName = parent.getAttribute('data-carousel');
    let left = parent.querySelector('.arrow-left');
    let right = parent.querySelector('.arrow-right');
    let itemsBack = qsArray('.carousel-content.back .item', parent);
    let itemsFront = qsArray('.carousel-content.front .item', parent);
    let length = itemsBack.length;
    let index = 0;
    let prevIndex = 0;

    left.addEventListener('click', function () {
      index = index > 0 ? index - 1 : length - 1;
      update();
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'event': 'carousel',
        'carouselName': carouselName, //ex: about, staff, menu, screenshots, etc
        'position': index + 1, //ex 1, 2, 3,4.
        'arrowClick': 'left' //ex. 'left', 'right', 'none'
      });
      // console.log('Track', {
      //   'event': 'carousel',
      //   'carouselName': carouselName, //ex: about, staff, menu, screenshots, etc
      //   'position': index + 1, //ex 1, 2, 3,4.
      //   'arrowClick': 'left' //ex. 'left', 'right', 'none'
      // });
    });
    right.addEventListener('click', function () {
      index = index + 1 < length ? index + 1 : 0;
      update();
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'event': 'carousel',
        'carouselName': carouselName, //ex: about, staff, menu, screenshots, etc
        'position': index + 1, //ex 1, 2, 3,4.
        'arrowClick': 'left' //ex. 'left', 'right', 'none'
      });
      // console.log('Track', {
      //   'event': 'carousel',
      //   'carouselName': carouselName, //ex: about, staff, menu, screenshots, etc
      //   'position': index + 1, //ex 1, 2, 3,4.
      //   'arrowClick': 'right' //ex. 'left', 'right', 'none'
      // });
    });

    function update () {
      itemsFront[prevIndex].classList.remove('is-visible');
      itemsFront[prevIndex].classList.remove('is-active');
      itemsFront[index].classList.add('is-active');
      itemsFront[index].clientHeight;
      itemsFront[index].classList.add('is-visible');

      itemsBack[prevIndex].classList.remove('is-visible');
      itemsBack[prevIndex].classList.remove('is-active');
      itemsBack[index].classList.add('is-active');
      itemsBack[index].clientHeight;
      itemsBack[index].classList.add('is-visible');

      prevIndex = index;
    }

    update();

    // console.log('Carousel Name', carouselName);
    // console.log('Constructor', this);
  }
}

export default PokemonCarousel;
