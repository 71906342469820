import components from '../utilities/components';

let LeavingSite = function () {
  if (LeavingSite.prototype._singletonInstance) {
    return LeavingSite.prototype._singletonInstance;
  }
  LeavingSite.prototype._singletonInstance = this;

  let $component = $('#leaving-site-component');
  let $close = $component.find('.button-close');
  let $cancel = $component.find('.button-cancel');
  let $confirm = $component.find('.button-confirm');

  $close.on('click', () => {
    components.close($component);
  });

  $cancel.on('click', () => {
    components.close($component);
  });

  $confirm.on('click', () => {
    components.close($component);
  });

  // $(document.body).on('click', 'a:not([data-standard-link])', onLinkClick);
  $(document.body).on('click', '[data-href]', onLinkClick);

  function onLinkClick(e) {

    // don't process cancel/continue button on the components
    if ($component.hasClass('is-active') || $component.hasClass('is-visible')) {
      return;
    }

    // get link info to attach to leaving site component
    let target = e.currentTarget;

    // get data-button attribute for analytics
    let dataButton = target.getAttribute('data-button');
    let dataPosition = target.getAttribute('data-position');
    let dataStore = target.getAttribute('data-store');
    if ( dataButton !== null && dataButton !== "" ) {
      if ( dataStore === 'ios' || dataStore === 'google' || dataStore === 'switch') {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          'event': 'button-click',
          'appStore': dataStore, //ex: ios,google,switch
          'buttonName': dataButton,
          'buttonPlacement': dataPosition
        });
        console.log('datalayer push ', {
          'event': 'button-click',
          'appStore': dataStore, //ex: ios,google,switch
          'buttonName': dataButton,
          'buttonPlacement': dataPosition
        });
      } else if (dataButton === "buy-box-art" || dataButton === "buy-nav") {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          'event': 'shopnow',
          'buttonType': dataButton
        });
        console.log('datalayer push ', {
          'event': 'shopnow',
          'buttonType': dataButton
        });
      } else {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          'event': 'button-click',
          'buttonName': dataButton
        });
        console.log('datalayer push ', {
          'event': 'button-click',
          'buttonName': dataButton
        });
      }
    }
    let url = target.getAttribute('data-href');
    $confirm.attr('href', url);

    if ( dataButton !== null && dataButton !== "" ) {
      $confirm.attr('data-button', dataButton);
    }
    components.open($component);
    e.preventDefault();
    e.stopPropagation();
    e.stopImmediatePropagation();
  }

  console.log('LeavingSite', this);
};

export default LeavingSite;
