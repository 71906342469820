import Hero from "./hero";
import PokemonCarousel from "./pokemon-carousel";
import CharacterJumps from "./character-jumps";

import { qsArray } from "../utilities";


class Home {
  constructor() {

    new Hero();
    new PokemonCarousel('gameplay-carousel');
    new PokemonCarousel('pokemon-carousel');
    new PokemonCarousel('item-carousel');

    new CharacterJumps(qsArray('#hero .jump-wrap'));
    new CharacterJumps(qsArray('#welcome-side-characters .jump-wrap'));
    // new CharacterJumps(qsArray('#news-side-character .jump-wrap'));
    new CharacterJumps(qsArray('#app-store .jump-wrap'));

    console.log('Constructor', this);
  }
}

export default Home;
