import VideoPlayer from "./components/video";
import LeavingSite from "./components/leaving-site";
import LocaleSelector from "./components/locale-selector";
import IsVisible from "./components/is-visible";
import MobileNav from "./components/mobile-nav";
import smoothscroll from "./components/smoothscroll";
import ScrollArrow from "./components/scroll-arrow";
import Home from "./home";

$(function (){
  $(".pokemon-gus-container").GlobalUtilityStrip();

  // initialize the smooth scrolling polyfill
  smoothscroll.polyfill();

  document.body.classList.add('dom-ready');

  window.trailer = new VideoPlayer();
  new LeavingSite();
  new LocaleSelector();
  // new MobileNav();
  new IsVisible();
  // new ScrollArrow();

  let page = document.body.getAttribute('data-page');
  // let desktopNav = document.getElementById('desktop-nav');
  // desktopNav.querySelector(`.nav-${page}`).classList.add('is-active');

  switch (page) {
    case 'home':
      let home = new Home();
      break;
  }

});

// ETS Test Commit
