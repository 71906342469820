import { qsArray, debounce } from "../utilities";
import AnimationLoop from "../utilities/animation-loop";

let DESKTOP_WIDTH = 260;
let DESKTOP_HEIGHT = 344;

let MOBILE_WIDTH = 195;
let MOBILE_HEIGHT = 258;

let width = DESKTOP_WIDTH;
let height = DESKTOP_HEIGHT;

let scale = 1;

class Hero {
  constructor() {

    let hero = this;

    let steamWrap = document.getElementById('steam-wrap')
    let steamCanvas = document.getElementById('steam-canvas')
    let steamElArray = qsArray('.steam', steamWrap);
    let loadCount = 0;
    let steamSrc = [];

    hero.el = { steamElArray, steamCanvas, steamSrc };

    steamElArray.forEach(el => {
      let ext = Modernizr.webp ? '.webp' : '.png';
      let src = el.getAttribute('data-src');
      let img = new Image();
      img.onload = function () {
        loadCount++;
        // el.appendChild(img);
        checkLoadCount();
      }
      img.src = src + ext;
      steamSrc.push(img);
    });

    function checkLoadCount () {
      if (loadCount === steamElArray.length) {
        hero.animate();
        steamWrap.classList.add('is-active');
      }
    }

    console.log('Constructor', this);
  }
  animate () {
    let hero = this;
    let {
      steamElArray,
      steamCanvas,
      steamSrc
    } = hero.el;
    let active = 0;

    steamCanvas.width = DESKTOP_WIDTH;
    steamCanvas.height = DESKTOP_HEIGHT;
    let ctx = steamCanvas.getContext('2d');

    function onResize () {
      if (window.innerWidth < 1024 && window.innerWidth >= 768) {
        scale = window.innerWidth / 1024;
      } else if (window.innerWidth < 540) {
        scale = window.innerWidth / 540;
      } else {
        scale = 1;
      }
      if (window.innerWidth < 768) {
        width = MOBILE_WIDTH * scale;
        height = MOBILE_HEIGHT * scale;
      } else {
        width = DESKTOP_WIDTH * scale;
        height = DESKTOP_HEIGHT * scale;
      }
      steamCanvas.width = width;
      steamCanvas.height = height;
    }
    onResize();
    let debouncedResize = debounce(onResize, 250);
    window.addEventListener('resize', debouncedResize);

    function update () {
      ctx.clearRect(0,0, width, height);
      ctx.drawImage(steamSrc[active], 0, 0, width, height);
      active = active === steamElArray.length - 1 ? 0 : active + 1;
    }

    let animLoop = new AnimationLoop(update, hero, 1000 / 12);

  }
}

export default Hero;
